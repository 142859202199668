import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export const LinkShortener = ({ domains }: { domains: string[] }) => {
  const [generatedLink, setGeneratedLink] = useState();
  return (
    <div className="bg-background dark relative mt-10 rounded-lg px-4 py-8 text-left">
      <div className="absolute bottom-2 right-20 hidden lg:flex">
        <img src="/line-vector.svg" alt="" />
      </div>
      <h4 className="text-2xl font-semibold text-white md:text-3xl">
        Shorten a Long Link
      </h4>
      <form
        className="mt-5 flex flex-col gap-5"
        onSubmit={(e) => {
          e.preventDefault();
          const fd = new FormData(e.target as HTMLFormElement);
          const currId = fd.get("id") as string | null;
          if (currId && currId.length >= 2) fd.set("id", "/" + fd.get("id"));
          else fd.delete("id");
          fetch(
            "https://" + import.meta.env.PUBLIC_API_URL + "/tools/shortener",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(Object.fromEntries(fd.entries())),
            },
          )
            .then((res) => res.json())
            .then((data) => setGeneratedLink(data.url));
        }}
      >
        <div>
          <Label htmlFor="url" className="text-white">
            Paste a long URL
          </Label>
          <Input
            id="url"
            type="url"
            name="link"
            required
            placeholder="Paste your long URL here"
            className="mt-2 max-w-[1000px] bg-white text-slate-900"
          />
        </div>
        <div className="flex items-end gap-2">
          <div>
            <div className="flex items-end gap-2">
              <div>
                <Label htmlFor="domain" className="text-white">
                  Domain
                </Label>
                <Select defaultValue={domains[0]} name="domain">
                  <SelectTrigger
                    className="mt-2 max-w-[280px] bg-white text-slate-900"
                    aria-label="Choose a domain"
                  >
                    <SelectValue placeholder="Choose a domain" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      <SelectLabel>Domains</SelectLabel>
                      {domains.map((domain) => {
                        return (
                          <SelectItem value={domain} key={domain}>
                            {domain}
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
              <span className="hidden text-4xl text-white sm:inline">/</span>
              <div>
                <Label htmlFor="back-half" className="text-white">
                  Custom back-half
                </Label>
                <Input
                  id="back-half"
                  name="id"
                  type="text"
                  maxLength={8}
                  minLength={2}
                  placeholder="my-cool-link"
                  className="mt-2 bg-white text-slate-900"
                />
              </div>
            </div>
          </div>
        </div>
        <Button
          type="submit"
          className="pointer-events-auto w-52 bg-slate-700 hover:bg-slate-600"
        >
          Get Link
        </Button>
        {generatedLink && (
          <div>
            <a
              className="text-foreground inline-block underline"
              href={generatedLink}
              target="_blank"
            >
              {generatedLink}
            </a>
          </div>
        )}
      </form>
    </div>
  );
};
